import React from 'react';
import { Link } from 'gatsby';
import TimpFullLogo from '../../svg/timpfull.svg';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div className="container flex justify-between flex-col sm:flex-row items-center mx-auto py-4 px-8">
      <div className="items-center text-2xl">
        <Link to="/">
          <img src={TimpFullLogo} alt="logo" />
        </Link>
      </div>
      <div className="flex items-center mt-4 sm:mt-0">
        <div>
          <Link className="px-4" to="/">
            Home
          </Link>
          <Link className="px-4" to="/about">
            About Us
          </Link>
          <Link className="px-4" to="/services">
            Services
          </Link>
        </div>
        <div className="hidden md:block">
          <Link to="/contact">
            <Button className="text-sm">Contact Us</Button>
          </Link>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
