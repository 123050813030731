import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon.ico';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`;

const SEO = ({ title, description, page }) => {
  const { site } = useStaticQuery(query);

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${page}`
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="viewport" />
      <meta name="description" content={seo.description} />
      <meta property="og.description" content={seo.description} />
      <meta property="og.url" content={seo.url} />
      <meta property="og.title" content={seo.title} />
      <meta property="og.locale" content="en_US" />
      <meta property="og.type" content="website" />
      <meta property="og.site_name" content="Timp Accounting" />
      <meta property="og.image" content="/images/timp.svg" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <link rel="icon" href={favicon} />
      <link rel="canonical" href={seo.url} />

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "AccountingService",
            "@id": "${seo.url}",
            "url": "${seo.url}",
            "name": "Timpanogos Accounting",
            "logo": "/images/timp.svg",
            "image": "/images/timp.svg",
            "telephone": "801-663-1277",
            "address": "Orem, UT",
            "sameAs":[
              "https://www.facebook.com/timpanogosaccounting",
              "https://www.linkedin.com/company/timpanogos-accounting"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  page: PropTypes.string
};

SEO.defaultProps = {
  title: null,
  description: null,
  page: ''
};
