import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Call Us Today</h2>
        <a href="tel:18016631277" className="mt-4 block">
          801-663-1277
        </a>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/about">About Us</a>
          </li>
          <li>
            <a href="/services">Services</a>
          </li>
          <li>
            <a
              href="https://timpaccounting.sharefile.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ShareFile
            </a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a
              href="https://www.facebook.com/timpaccounting"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/timpanogos-accounting"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="text-center">
      <p className="mt-5">© Copyright 2020 | Timpanogos Accounting, LLC | All Rights Reserved</p>
    </div>
  </footer>
);

export default Footer;
